//@ts-nocheck
import { renderHtml, getOptionsFromNode } from '~/lib/htmlToVue'
import { h } from 'vue'
import Button from '~/components/atoms/button/Button.vue'
import { NuxtLink } from '#components'

/*

====================

VERY VERY IMPORTANT NOTE:

====================

this component uses the htmlToVue library that is in the PROJECT SRC FOLDER, not!!!! in the node_modules
see above import statement

the library is still part of the package.json and is installed in the node_modules, but it is not used
because the import statement is from the src folder


====================

*/

const getAnchorClasses = (classes) => {
  if (!classes) return 'link-default'
  if (classes.split(' ').includes('inline-link')) return 'link-default'
  return classes
}
const conf = {
  config: {
    // This object sets up the container of the HTML that gets rendered
    container: {
      type: 'div',
    },
    // This object contains Vue components that substitutes HTML node (look at next section)
    extraComponentsMap: {
      customAnchorConfig: {
        conditions(node) {
          return (
            node.type === 'tag' && // is a tag
            node.name === 'a' && // is an anchor
            !node.attrs?.class?.includes('sf-button') // does not have button class
          )
        },
        renderer(node, children, createElement, context) {
          const options = getOptionsFromNode(node)
          return createElement(
            NuxtLink,
            {
              class: getAnchorClasses(options.class),
              ...options.attrs,
            },
            () => [...children], // parsed children, in our case it will probably be just a text child
          )
        },
      },
      customButtonConfig: {
        conditions(node) {
          return (
            node.type === 'tag' && // is a tag
            node.name === 'a' && // is an anchor
            node.attrs?.class?.match(/sf-button\s?/) // has button class
          )
        },
        renderer(node, children, createElement, context) {
          const options = getOptionsFromNode(node)

          const primary = options.class.includes('primary-button')
          const secondary = options.class.includes('secondary-button')
          return createElement(
            Button,
            {
              ...options.attrs,
              primary,
              secondary,
              slide: true,
            },
            () => [...children], // parsed children, in our case it will probably be just a text child
          )
        },
      },
    },
    /*
	   You can conditionally pass a function which transform text Nodes (e.g.: to handle html entities)
	   */
    textTransformer: (text) => text.replace(/&amp;/g, '&'),
  },
  rawHtml: '<p> something is not right :eyes: </p>',
}

const TextSpecial = (props, context) => {
  return renderHtml(props.text || conf.rawHtml, conf.config, h, context)
}

TextSpecial.props = ['text']

export default TextSpecial
